import { Route } from "next"

import { TokenType } from "@supernovaio/sdk"

import { getHomeRoute } from "./getHomeRoute"

// in most cases, subsections shouldn't be included, since most of the time
// we want to redirect to the first section after brand route -> /:wsid+slug/:dsId+slug/:versionId+slug/:brandId+slug
export const BrandRouteDestination = {
  documentation: "documentation",
  documentationSettings: "documentation/settings/domain-and-privacy",
  tokens: "tokens",
  components: "components",
  assets: "assets",
  settings: "settings/ws-general",
  settingsMembers: "settings/members",
  feedback: "feedback",
  designData: "design-data",
  code: "code",
  plan: "plan",
} as const

export enum DocumentationSettingsRouteDestination {
  domainAndPrivacy = "domain-and-privacy",
  workflows = "workflows",
  npmRegistry = "npm-registry",
  storybookAndNpm = "storybook-and-npm",
  advanced = "advanced",
}

export enum SettingsRouteDestination {
  userProfile = "user-profile",
  wsGeneral = "ws-general",
  wsIntegrations = "ws-integrations",
  wsIpWhitelisting = "ws-ip-whitelisting",
  dsGeneral = "ds-general",
  dsMembers = "ds-members",
  dsVersions = "ds-versions",
  dsBrands = "ds-brands",
  sso = "sso",
  wsBilling = "ws-billing",
  designSystems = "design-systems",
  members = "members",
  authentication = "authentication",
  notifications = "notifications",
}

export enum BillingPlanPageRouteDestination {
  failed = "result-failed",
  success = "result-success",
  selectPlan = "select-plan",
}

export enum CodeRouteDestination {
  pipelines = "pipelines",
  // exporters
  assets = "assets",
  designTokens = "design-tokens",
  other = "other",
}

export enum PipelinesRouteDestination {
  newExporter = "pipelines/new/exporter",
  newEvent = "pipelines/new/event",
  newDelivery = "pipelines/new/delivery",
}

export enum DataSourcesRouteSection {
  figma = "figma",
  tokensStudio = "tokens-studio",
  figmaVariablesPlugin = "figma-variables-plugin",
}

export type BaseRouteType =
  | Route<`/${string}/${string}/${string}/${string}`>
  | Route<`/${string}/${string}/${string}`>
  | Route<`/${string}/${string}`>
  | Route<`/${string}`>
  | Route<ReturnType<typeof getHomeRoute>>

export type BrandRouteDestinationType =
  (typeof BrandRouteDestination)[keyof typeof BrandRouteDestination]

export type BrandRouteType = Route<`/${string}/${string}/${string}/${string}`>
export type BrandRouteWithDestinationType =
  Route<`/${string}/${string}/${string}/${string}/${BrandRouteDestinationType}`>

export type SettingsRouteType =
  Route<`/${string}/${string}/${string}/${string}/settings/${SettingsRouteDestination}`>

export type BasicSettingsRouteType =
  Route<`/settings/${SettingsRouteDestination}`>

export type DocumentationRouteType =
  Route<`/${string}/${string}/${string}/${string}/documentation/${string}`>

export type DocumentationSettingsRouteType = Route<
  | `/${string}/${string}/${string}/${string}/documentation/settings/${string}`
  | `/${string}/${string}/${string}/${string}/documentation/settings/customization/${string}`
>

export type TokenRouteType =
  Route<`/${string}/${string}/${string}/${string}/tokens/${TokenType}`>

export type ComponentRouteType = Route<
  | `/${string}/${string}/${string}/${string}/components/${string}`
  | `/${string}/${string}/${string}/${string}/components/create`
>

export type CodeRouteType = Route<
  | `/${string}/${string}/${string}/${string}/code/exporters/designTokens`
  | `/${string}/${string}/${string}/${string}/code/exporters/assets`
  | `/${string}/${string}/${string}/${string}/code/exporters/pipelines`
  | `/${string}/${string}/${string}/${string}/code/exporters/pipelines/new/exporters`
>
export type DataSourcesRouteType =
  Route<`/${string}/${string}/${string}/${string}/design-data/${DataSourcesRouteSection}`>

export type BillingPlanRouterType =
  Route<`/${string}/${string}/${string}/${string}/plan/${BillingPlanPageRouteDestination}`>
