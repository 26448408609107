import { AclOperation, AclOperations } from "@supernova-studio/acl"

import { type ResourceAction } from "../hooks/usePermissions"

export const CODE_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.ExporterRead,
  AclOperations.ViewCodegenData,
]

export const EXPORTER_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.ExporterUpdate,
]

export const DESIGN_DATA_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionContentRead,
  AclOperations.ViewFigmaDesignData,
]

export const COMPONENT_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemComponentRead,
]

export const DESIGN_TOKENS_MODIFY_PERMISSIONS: AclOperation[] = [
  // TODO (Seva): replace with a proper ACL operation when BE adds it
  AclOperations.DesignSystemComponentUpdate,
]

export const COMPONENT_CREATE_PAGE_PERMISSIONS: ResourceAction[] = [
  { resource: "tokens", action: "create" },
]

export const COMPONENT_UPDATE_PAGE_PERMISSIONS: ResourceAction[] = [
  { resource: "tokens", action: "update" },
]

export const SELECT_PLAN_PAGE_PERMISSIONS: ResourceAction[] = [
  { resource: "workspaces", action: "subscription.update" },
]

export const TOKEN_CREATE_PAGE_PERMISSIONS: ResourceAction[] = [
  { resource: "tokens", action: "create" },
]

export const TOKEN_UPDATE_PAGE_PERMISSIONS: ResourceAction[] = [
  { resource: "tokens", action: "update" },
]

export const ASSETS_FONTS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemFontRead,
  AclOperations.ViewFontsPage,
]

export const DOCS_SETTINGS_PERMISSIONS: AclOperation[] = [
  AclOperations.UpdateDocumentationSettings,
]

export const DOCS_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.DocumentationUpdate,
]

export const SETTINGS_WS_GENERAL_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceUpdate,
]

export const SETTINGS_SSO_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceSsoManage,
]

export const SETTINGS_IP_WHITELIST_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceWhitelistManage,
]

export const SETTINGS_WS_BILLING_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceSubscriptionRead,
  AclOperations.ViewBillingPage,
]

export const SETTINGS_WS_MEMBERS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceMembersRead,
  AclOperations.ViewWorkspaceMembersPage,
]

export const SETTINGS_DESIGN_SYSTEMS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemRead,
  AclOperations.ViewWorkspaceDesignSystemsPage,
]

export const SETTINGS_DS_GENERAL_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemUpdate,
]

export const SETTINGS_DS_MEMBERS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemMemberRead,
  AclOperations.ViewWorkspaceDesignSystemMembersPage,
]

export const SETTINGS_DS_VERSIONS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionRead,
]

export const SETTINGS_DS_BRANDS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemBrandRead,
  AclOperations.ViewBrandsPage,
]

export const SETTINGS_WS_INTEGRATION_READ_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceIntegrationsRead,
]

export const SETTINGS_WS_INTEGRATION_FIGMA_PROVIDERS_CREATE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsCreate]

export const SETTINGS_WS_INTEGRATION_GIT_PROVIDERS_CREATE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsCreate]

export const SETTINGS_WS_INTEGRATION_FIGMA_PROVIDERS_DELETE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsDelete]

export const SETTINGS_WS_INTEGRATION_GIT_PROVIDERS_DELETE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsDelete]
